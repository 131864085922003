import { QueryFilter } from "@models/api-request.model";
import { Attachment, Exportable, Exporter } from "@models/index";

export class Firmware implements Exportable {

  id:        number = 0;
  version:   number | undefined;
  name:      string | undefined;

  attachment:  Attachment = new Attachment();

  constructor() {}

}

export class FirmwareFilter implements QueryFilter {

  name:      string | undefined;

}


export class FirmwareExporter implements Exporter  {

  name;

  constructor() {

    this.name= {
      nameToShow: 'Nombre'
    };
  }
}
